// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useShare from "../hooks/useShare";
// Context
// ...
// Components
import Layout from "../components/Layout";
import TrackList from "../components/TrackList";
import TrackListMap from "../components/TrackListMap";
import TrackViewArtwork from "../components/TrackViewArtwork";

const TrackTemplate = ({ data, pageContext: { locale } }) => {

  // Content
  const track = data.contentfulTrack;
  const artwork = track.artwork || (track.projectLink && track.projectLink.thumbnail) || (track.projectLink && track.projectLink.cover);

  return (
    <Layout
      title={`${track.title} by ${track.subtitle} | MSCTY.SPACE`}
      headerTitles={null}
      shareImage={null}
			locale={locale}
      colours={{
        primary: '#FFFFFF',
        secondary: '#0B0B0A'
      }}
      isPage={false}
    >
      <div className="listen-page">
        <div className="track-view">
          <div className="track-view__left">
            {track.location ? (
              <TrackListMap tracks={[track]} locale={locale} />
            ) : artwork ? (
              <TrackViewArtwork {...track} artwork={artwork} />
            ) : null}
          </div>
          <div className="track-view__right">
            <div className="track-list">
              <TrackList tracks={[track]} locale={locale} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

TrackTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default TrackTemplate

export const TrackTemplatePageQuery = graphql`
  query TrackTemplateQuery($slug: String!, $locale: String!) {
    contentfulTrack(
			slug: { eq: $slug }, 
			node_locale: { eq: $locale }
		) {
      ...ContentfulTrackFragment
    }
  }
`
